import React from 'react';

import NavMl from './navigation/menu/navMl';

const Footer = ({ navLinks }) => {
  return (
    <>
      <footer className="footer">
        <nav
          className="navbar has-text-white is-text-indication"
          role="navigation"
          aria-label="footer navigation"
        >
          <NavMl navLinks={navLinks} cssClass="menu-footer-ml" />
        </nav>
      </footer>
    </>
  );
};

export default Footer;
