import React from 'react';

// Bouton des paramètres OneTrust de cookies
const CookieSetting = ({ label }) => {
  const OTToggleInfoDisplay = () => {
    if (window.OneTrust != null) {
      window.OneTrust.ToggleInfoDisplay();
    }
  };

  return (
    <a onClick={OTToggleInfoDisplay} className="optanon-show-settings">
      {label}
    </a>
  );
};

export default CookieSetting;
