import React from 'react';
import { Link } from 'gatsby';

import { CookieSetting } from '../../../../plugins/epsilon-gatsby-plugin-onetrust';

const NavMl = ({ navLinks, cssClass }) => {
  return (
    <ul className={` ${cssClass ? cssClass : ''}`}>
      {navLinks[0]?.colLegaleMention?.map((item, i) => {
        return (
          <li key={i}>
            <Link to={`/${item.slug}`}>{item.title}</Link>
          </li>
        );
      })}
      <li>
        <CookieSetting label="Manage your cookies" />
      </li>
    </ul>
  );
};

export default NavMl;
