import React, { Component } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.expandMenuRef = React.createRef();

    this.state = {
      lastScroll: 0,
      scrollUp: 'scroll-up',
      scrollDown: 'scroll-down',
      width: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.showMenuScroll);
    document.addEventListener('click', this.handleClickOutsideMenu);
    window.addEventListener('resize', this.resizeWindow);
    this.resizeWindow();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.showMenuScroll);
    document.removeEventListener('click', this.handleClickOutsideMenu);
    window.addEventListener('resize', this.resizeWindow);
  }

  // Get width window
  resizeWindow = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { headerCssClass } = this.props;

    return (
      <>
        <header className={`header ${headerCssClass ? headerCssClass : ''}`}>
          <nav className="navbar">
            <div className="navbar-brand">
              <Link className="navbar-item" to="/">
                <StaticImage
                  src="../images/logo-publicis-france.png"
                  placeholder="blurred"
                  width={130}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Logo Publicis France"
                />
              </Link>
            </div>
          </nav>
        </header>
      </>
    );
  }
}
