/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { CookieList } from '../../plugins/epsilon-gatsby-plugin-onetrust';

import Header from './header';
import Footer from './footer';

const Layout = ({ children, headerCssClass, mainCssClass }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleAndMLQuery {
      site {
        siteMetadata {
          title
        }
      }

      allContentfulMenuMl {
        nodes {
          node_locale
          colLegaleMention {
            slug
            title
          }
        }
      }

      allFile(
        filter: { sourceInstanceName: { eq: "css" }, name: { eq: "print" } }
      ) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);
  const navLinks = data.allContentfulMenuMl.nodes;

  const printCSS = data.allFile.nodes[0].publicURL;

  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href={printCSS} media="print" />
      </Helmet>
      <Header
        siteTitle={data.site.siteMetadata?.title || 'Title'}
        headerCssClass={headerCssClass}
        navLinks={navLinks}
      />
      <main className={`main ${mainCssClass ? mainCssClass : ''}`}>
        {children}
      </main>
      <Footer navLinks={navLinks} />
      <CookieList />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
